import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from 'axios'
import Vue from 'vue'
import router from '../../../router'

export default function useAssetsList() {
  const userToken = Vue.$cookies.get('userToken')
  const toast = useToast()
  const refAssetsListTable = ref(null)
  const refAssetsListTableOnGoing = ref(null)
  const refAssetsListTableNA = ref(null)
  const refAssetsListTableDisposed = ref(null)
  const tableColumns = [
    { key: 'AssetID', sortable: true },
    { key: 'ItemName', sortable: true },
    { key: 'Origin', sortable: true },
    { key: 'CurrentLocation', sortable: true },
    { key: 'CurrentCondition', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'DateModified', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsDisposed = [
    { key: 'AssetID', sortable: true },
    { key: 'ItemName', sortable: true },
    { key: 'Origin', sortable: true },
    { key: 'CurrentLocation', sortable: true },
    { key: 'CurrentCondition', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'DateDisposed', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const startDateFilter = ref('')
  const filterBranch = ref('')
  const filterAssetGroup = ref('')
  const endDateFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refAssetsListTable.value ? refAssetsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const perPageOnGoing = ref(10)
  const totalItemsOnGoing = ref(0)
  const currentPageOnGoing = ref(1)
  const perPageOptionsOnGoing = [10, 25, 50, 100]
  const searchQueryOnGoing = ref('')
  const sortByOnGoing = ref('id')
  const isSortDirDescOnGoing = ref(true)
  const roleFilterOnGoing = ref(null)
  const statusFilterOnGoing = ref(null)
  const startDateFilterOnGoing = ref('')
  const filterBranchOnGoing = ref('')
  const filterAssetGroupOnGoing = ref('')
  const endDateFilterOnGoing = ref('')

  const dataMetaOnGoing = computed(() => {
    const localItemsCount = refAssetsListTableOnGoing.value ? refAssetsListTableOnGoing.value.localItems.length : 0
    return {
      from: perPageOnGoing.value * (currentPageOnGoing.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageOnGoing.value * (currentPageOnGoing.value - 1) + localItemsCount,
      of: totalItemsOnGoing.value,
    }
  })

const perPageNA = ref(10)
const totalItemsNA = ref(0)
const currentPageNA = ref(1)
const perPageOptionsNA = [10, 25, 50, 100]
const searchQueryNA = ref('')
const sortByNA = ref('id')
const isSortDirDescNA = ref(true)
const roleFilterNA = ref(null)
const statusFilterNA = ref(null)
const startDateFilterNA = ref('')
const filterBranchNA = ref('')
const filterAssetGroupNA = ref('')
const endDateFilterNA = ref('')

const dataMetaNA = computed(() => {
  const localItemsCount = refAssetsListTableNA.value ? refAssetsListTableNA.value.localItems.length : 0
  return {
    from: perPageNA.value * (currentPageNA.value - 1) + (localItemsCount ? 1 : 0),
    to: perPageNA.value * (currentPageNA.value - 1) + localItemsCount,
    of: totalItemsNA.value,
  }
})
const perPageDisposed = ref(10)
const totalItemsDisposed = ref(0)
const currentPageDisposed = ref(1)
const perPageOptionsDisposed = [10, 25, 50, 100]
const searchQueryDisposed = ref('')
const sortByDisposed = ref('id')
const isSortDirDescDisposed = ref(true)
const roleFilterDisposed = ref(null)
const statusFilterDisposed = ref(null)
const startDateFilterDisposed = ref('')
const filterBranchDisposed = ref('')
const filterAssetGroupDisposed = ref('')
const endDateFilterDisposed = ref('')

const dataMetaDisposed = computed(() => {
  const localItemsCount = refAssetsListTableDisposed.value ? refAssetsListTableDisposed.value.localItems.length : 0
  return {
    from: perPageDisposed.value * (currentPageDisposed.value - 1) + (localItemsCount ? 1 : 0),
    to: perPageDisposed.value * (currentPageDisposed.value - 1) + localItemsCount,
    of: totalItemsDisposed.value,
  }
})
  const refetchData = () => {
    refAssetsListTable.value.refresh()
    refAssetsListTableNA.value.refresh()
    refAssetsListTableOnGoing.value.refresh()
    refAssetsListTableDisposed.value.refresh()
  }

  watch([
    currentPage,
    perPage,
    searchQuery,
    roleFilter,
    statusFilter,
    sortBy,
    isSortDirDesc,
    startDateFilter,
    endDateFilter,
    filterBranch,
    filterAssetGroup,
    currentPageOnGoing,
    perPageOnGoing,
    searchQueryOnGoing,
    roleFilterOnGoing,
    statusFilterOnGoing,
    sortByOnGoing,
    isSortDirDescOnGoing,
    startDateFilterOnGoing,
    endDateFilterOnGoing,
    filterBranchOnGoing,
    filterAssetGroupOnGoing,
    currentPageNA,
    perPageNA,
    searchQueryNA,
    roleFilterNA,
    statusFilterNA,
    sortByNA,
    isSortDirDescNA,
    startDateFilterNA,
    endDateFilterNA,
    filterBranchNA,
    filterAssetGroupNA,
    currentPageDisposed,
    perPageDisposed,
    searchQueryDisposed,
    roleFilterDisposed,
    statusFilterDisposed,
    sortByDisposed,
    isSortDirDescDisposed,
    startDateFilterDisposed,
    endDateFilterDisposed,
    filterBranchDisposed,
    filterAssetGroupDisposed,
  ], () => {
    refetchData()
  })

  const fetchAssets = (ctx, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const to = perPage.value * (currentPage.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ASSET}?limit=${perPage.value}&offset=${to}&search=${searchQuery.value}&type=ongoing&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}&branch_id=${filterBranch.value.value}&group=${encodeURIComponent(filterAssetGroup.value)}`, { headers })
      .then(response => {
        totalItems.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchAssetsOnGoing = (ctx, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const to = perPage.value * (currentPage.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ASSET}?limit=${perPageOnGoing.value}&offset=${to}&search=${searchQueryOnGoing.value}&is_ongoing_dispose=true&date_start=${startDateFilterOnGoing.value}&date_end=${endDateFilterOnGoing.value}&branch_id=${filterBranchOnGoing.value.value}&group=${encodeURIComponent(filterAssetGroupOnGoing.value)}`, { headers })
      .then(response => {
        totalItemsOnGoing.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchAssetsNA = (ctx, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const to = perPage.value * (currentPage.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ASSET}?limit=${perPageNA.value}&offset=${to}&search=${searchQueryNA.value}&needs_approval=true&is_ongoing_dispose=true&date_start=${startDateFilterNA.value}&date_end=${endDateFilterNA.value}&branch_id=${filterBranchNA.value.value}&group=${encodeURIComponent(filterAssetGroupNA.value)}`, { headers })
      .then(response => {
        totalItemsNA.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchAssetsDisposed = (ctx, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const to = perPage.value * (currentPage.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ASSET}?limit=${perPageDisposed.value}&offset=${to}&search=${searchQueryDisposed.value}&is_disposed=true&is_ongoing_dispose=true&date_start=${startDateFilterDisposed.value}&date_end=${endDateFilterDisposed.value}&branch_id=${filterBranchDisposed.value.value}&group=${encodeURIComponent(filterAssetGroupDisposed.value)}`, { headers })
      .then(response => {
        totalItemsDisposed.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchAssets,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAssetsListTable,
    roleFilter,
    statusFilter,
    startDateFilter,
    endDateFilter,
    filterBranch,
    filterAssetGroup,
    fetchAssetsNA,
    perPageNA,
    currentPageNA,
    totalItemsNA,
    dataMetaNA,
    perPageOptionsNA,
    searchQueryNA,
    sortByNA,
    isSortDirDescNA,
    refAssetsListTableNA,
    roleFilterNA,
    statusFilterNA,
    startDateFilterNA,
    endDateFilterNA,
    filterBranchNA,
    filterAssetGroupNA,
    fetchAssetsOnGoing,
    perPageOnGoing,
    currentPageOnGoing,
    totalItemsOnGoing,
    dataMetaOnGoing,
    perPageOptionsOnGoing,
    searchQueryOnGoing,
    sortByOnGoing,
    isSortDirDescOnGoing,
    refAssetsListTableOnGoing,
    roleFilterOnGoing,
    statusFilterOnGoing,
    startDateFilterOnGoing,
    endDateFilterOnGoing,
    filterBranchOnGoing,
    filterAssetGroupOnGoing,
    fetchAssetsDisposed,
    perPageDisposed,
    currentPageDisposed,
    totalItemsDisposed,
    dataMetaDisposed,
    perPageOptionsDisposed,
    searchQueryDisposed,
    sortByDisposed,
    isSortDirDescDisposed,
    refAssetsListTableDisposed,
    roleFilterDisposed,
    statusFilterDisposed,
    startDateFilterDisposed,
    endDateFilterDisposed,
    filterBranchDisposed,
    filterAssetGroupDisposed,
    tableColumns,
    tableColumnsDisposed,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
  }
}

<template>
  <div>
    <b-modal v-model="showModal" title="Confirmation"
      hide-footer>
      <p>Are you sure you want to dispose this asset?</p>
      <b-button @click="disposeAsset" variant="danger">Yes</b-button> &nbsp;
      <b-button @click="hideModal">No</b-button>
    </b-modal>
    <!-- Modal Upload-->
    <b-modal
      id="modal-upload"
      v-model="modalUpload"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Documents"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              v-if="documentsList.length > 0"
              class="list-group"
              style="height:300pxwidth:100%overflow-y:scroll"
            >
              <input
                id="materialRequestId"
                ref="invoiceMessage"
                v-model="materialRequestId"
                type="hidden"
                name="materialRequestId"
              />
              <li
  v-for="item in documentsList"
  :key="item"
  class="list-group-item d-flex justify-content-between align-items-center"
  style="cursor: pointer;"
>
  <span style="font-size:9px">{{ item.document_file_name }}</span>
  <div>
    <b-button
  :disabled="item.document_old"
  variant="primary"
  size="sm"
  class="mr-1"
  @click.stop="downloadAttachment(item)"
>
      <feather-icon icon="DownloadIcon" />
    </b-button>
    <b-button variant="danger" size="sm" @click.stop="deleteAttachment(item)">
      <feather-icon icon="TrashIcon" />
    </b-button>
  </div>
</li>
            </ul>
            <div v-if="documentsList.length == 0">
              No Files
            </div>
            <br />
            <form
              id="send_file_form"
              method="post"
              @submit.prevent="sendfiles"
              action=""
            >
              <b-row>
                <b-col cols="11" md="9">
          <b-form-file
            id="file"
            ref="uploadFile"
            placeholder="Choose one file or drop it here..."
drop-placeholder="Drop a file here..."
type="file"
@change="handleFilesUpload"
          />
          <div class="alert alert-info" role="alert">
                Max file upload is 2MB
              </div>
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendDocument()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-card no-body class="mb-0">
          <b-tabs>
        <b-tab title="Active">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
            <b-col cols="4">
              <label>Branch</label>
              <v-select-new v-model="filterBranch" :options="branches"/>
            </b-col>
            <b-col cols="4">
              <label>Asset Group</label>
              <v-select-new v-model="filterAssetGroup" :options="assetGroups"/>
            </b-col>
          </b-row>
          <hr>
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker v-model="startDateFilter" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker v-model="endDateFilter" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportAsset()" :disabled="isLoadingExport">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    v-if="hasPermission('assets_create') == true"
                    variant="primary"
                    @click="addNew()"
                  >
                    <span class="text-nowrap">Add Asset</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        <b-table
          ref="refAssetsListTable"
          class="position-relative"
          :items="fetchAssets"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: AssetID -->
          <template #cell(AssetID)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                data.item.asset_number
              }}</span>
            </div>
          </template>
          <!-- Column: Item Name -->
          <template #cell(ItemName)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                data.item.item.item_name
              }}</span>
            </div>
          </template>
          <!-- Column: Origin -->
          <template #cell(Origin)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                cutString(
                  data.item.origin
                    ? data.item.origin.branch_name
                    : "-",
                  30
                )
              }}</span>
            </div>
          </template>
          <!-- Column: Current Location -->
          <template #cell(CurrentLocation)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                cutString(
                  data.item.current
                    ? data.item.current.branch_name
                    : data.item.origin.branch_name,
                  30
                )
              }}</span>
            </div>
          </template>
          <!-- Column: Current Condition -->
          <template #cell(CurrentCondition)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                data.item.asset_condition
              }}</span>
            </div>
          </template>
          <!-- Column: DateCreated -->
          <template #cell(DateCreated)="data">
            {{
              dateSimple(
                data.item.asset_created_time,
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </template>
            <!-- Column: DateDisposed -->
            <template #cell(DateDisposed)="data">
              {{
                dateSimple(
                  data.item.asset_is_disposed_confirmed_by_fa_coal_manager_date,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="showDetail(data.item)">
                <feather-icon icon="ZoomInIcon" />
                <span class="align-middle ml-50">Detail</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showHistory(data.item)">
              <feather-icon icon="BookIcon" />
              <span class="align-middle ml-50">History</span>
            </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  userRole == 'finance'"
                @click="editAssets(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userRole === 'finance' && (!data.item.asset_is_disposed)"
                @click="disposeAsset(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">Dispose</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item
                v-if="
                  hasPermission('assets_delete') == true"
                @click="deleteAssets(data.item.asset_id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.from }} to
                   {{ dataMeta.to }} of
                  {{ dataMeta.of }} entries</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>

        <b-tab title="Dispose">
          <b-tabs>
            <b-tab title="On Going">
              <div class="m-2">
            <!-- Table Top -->
            <b-row>
            <b-col cols="4">
              <label>Branch</label>
              <v-select-new v-model="filterBranchOnGoing" :options="branches"/>
            </b-col>
            <b-col cols="4">
              <label>Asset Group</label>
              <v-select-new v-model="filterAssetGroupOnGoing" :options="assetGroups"/>
            </b-col>
          </b-row>
          <hr>
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker v-model="startDateFilterOnGoing" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker v-model="endDateFilterOnGoing" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportAsset()" :disabled="isLoadingExport">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageOnGoing"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryOnGoing"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    v-if="hasPermission('assets_create') == true"
                    variant="primary"
                    @click="addNew()"
                  >
                    <span class="text-nowrap">Add Asset</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refAssetsListTableOnGoing"
            class="position-relative"
            :items="fetchAssetsOnGoing"
            responsive
            :fields="tableColumnsDisposed"
            primary-key="id"
            :sort-by.sync="sortByOnGoing"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescOnGoing"
          >
            <!-- Column: AssetID -->
            <template #cell(AssetID)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.asset_number
                }}</span>
              </div>
            </template>
            <!-- Column: Item Name -->
            <template #cell(ItemName)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.item.item_name
                }}</span>
              </div>
            </template>
            <!-- Column: Origin -->
            <template #cell(Origin)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  cutString(
                    data.item.origin
                      ? data.item.origin.branch_name
                      : "-",
                    30
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Current Location -->
            <template #cell(CurrentLocation)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  cutString(
                    data.item.current
                      ? data.item.current.branch_name
                      : data.item.origin.branch_name,
                    30
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Current Condition -->
            <template #cell(CurrentCondition)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.asset_condition
                }}</span>
              </div>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.asset_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: DateModified -->
            <template #cell(DateModified)="data">
              {{
                dateSimple(
                  data.item.asset_updated_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="showDetailDisposal(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showHistory(data.item)">
                <feather-icon icon="BookIcon" />
                <span class="align-middle ml-50">History</span>
              </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'finance'"
                  @click="editAssets(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'laboratory-manager' && data.item.asset_is_disposed_confirmed_by_finance_id!=null && data.item.asset_is_disposed_confirmed_by_lab_manager_id==null"
                  @click="confirmDisposalByLabManager(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'regional-manager' && data.item.asset_is_disposed_confirmed_by_lab_manager_id!=null && data.item.asset_is_disposed_confirmed_by_regional_manager_id==null"
                  @click="confirmDisposalByRegionalManager(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'division-head' && data.item.asset_is_disposed_confirmed_by_regional_manager_id!=null && data.item.asset_is_disposed_confirmed_by_division_head_id==null"
                  @click="confirmDisposalByDivisionHead(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm!</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'fa-coal-manager' && data.item.asset_is_disposed_confirmed_by_division_head_id!=null && data.item.asset_is_disposed_confirmed_by_fa_coal_manager_id==null"
                  @click="confirmDisposalByFACoalManager(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          </b-tab>
          <b-tab title="Needs Approval">
            <div class="m-2">
            <!-- Table Top -->
            <b-row>
            <b-col cols="4">
              <label>Branch</label>
              <v-select-new v-model="filterBranchNA" :options="branches"/>
            </b-col>
            <b-col cols="4">
              <label>Asset Group</label>
              <v-select-new v-model="filterAssetGroupNA" :options="assetGroups"/>
            </b-col>
          </b-row>
          <hr>
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker v-model="startDateFilterNA" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker v-model="endDateFilterNA" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportAsset()" :disabled="isLoadingExport">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageNA"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptionsNA"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryNA"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    v-if="hasPermission('assets_create') == true"
                    variant="primary"
                    @click="addNew()"
                  >
                    <span class="text-nowrap">Add Asset</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refAssetsListTableNA"
            class="position-relative"
            :items="fetchAssetsNA"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortByNA"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescNA"
          >
            <!-- Column: AssetID -->
            <template #cell(AssetID)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.asset_number
                }}</span>
              </div>
            </template>
            <!-- Column: Item Name -->
            <template #cell(ItemName)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.item.item_name
                }}</span>
              </div>
            </template>
            <!-- Column: Origin -->
            <template #cell(Origin)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  cutString(
                    data.item.origin
                      ? data.item.origin.branch_name
                      : "-",
                    30
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Current Location -->
            <template #cell(CurrentLocation)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  cutString(
                    data.item.current
                      ? data.item.current.branch_name
                      : data.item.origin.branch_name,
                    30
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Current Condition -->
            <template #cell(CurrentCondition)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.asset_condition
                }}</span>
              </div>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.asset_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: DateDisposed -->
            <template #cell(DateDisposed)="data">
              {{
                dateSimple(
                  data.item.asset_is_disposed_confirmed_by_fa_coal_manager_date,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="showDetailDisposal(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showHistory(data.item)">
                <feather-icon icon="BookIcon" />
                <span class="align-middle ml-50">History</span>
              </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'finance'"
                  @click="editAssets(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'laboratory-manager' && data.item.asset_is_disposed_confirmed_by_finance_id!=null && data.item.asset_is_disposed_confirmed_by_lab_manager_id==null"
                  @click="confirmDisposalByLabManager(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'regional-manager' && data.item.asset_is_disposed_confirmed_by_lab_manager_id!=null && data.item.asset_is_disposed_confirmed_by_regional_manager_id==null"
                  @click="confirmDisposalByRegionalManager(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'division-head' && data.item.asset_is_disposed_confirmed_by_regional_manager_id!=null && data.item.asset_is_disposed_confirmed_by_division_head_id==null"
                  @click="confirmDisposalByDivisionHead(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'fa-coal-manager' && data.item.asset_is_disposed_confirmed_by_division_head_id!=null && data.item.asset_is_disposed_confirmed_by_fa_coal_manager_id==null"
                  @click="confirmDisposalByFACoalManager(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          </b-tab>
          <b-tab title="Disposed">
            <div class="m-2">
            <!-- Table Top -->
            <b-row>
            <b-col cols="4">
              <label>Branch</label>
              <v-select-new v-model="filterBranchDisposed" :options="branches"/>
            </b-col>
            <b-col cols="4">
              <label>Asset Group</label>
              <v-select-new v-model="filterAssetGroupDisposed" :options="assetGroups"/>
            </b-col>
          </b-row>
          <hr>
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker v-model="startDateFilterDisposed" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker v-model="endDateFilterDisposed" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportAsset()" :disabled="isLoadingExport">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageDisposed"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryDisposed"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    v-if="hasPermission('assets_create') == true"
                    variant="primary"
                    @click="addNew()"
                  >
                    <span class="text-nowrap">Add Asset</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refAssetsListTableDisposed"
            class="position-relative"
            :items="fetchAssetsDisposed"
            responsive
            :fields="tableColumnsDisposed"
            primary-key="id"
            :sort-by.sync="sortByDisposed"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescDisposed"
          >
            <!-- Column: AssetID -->
            <template #cell(AssetID)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.asset_number
                }}</span>
              </div>
            </template>
            <!-- Column: Item Name -->
            <template #cell(ItemName)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.item.item_name
                }}</span>
              </div>
            </template>
            <!-- Column: Origin -->
            <template #cell(Origin)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  cutString(
                    data.item.origin
                      ? data.item.origin.branch_name
                      : "-",
                    30
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Current Location -->
            <template #cell(CurrentLocation)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  cutString(
                    data.item.current
                      ? data.item.current.branch_name
                      : data.item.origin.branch_name,
                    30
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Current Condition -->
            <template #cell(CurrentCondition)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.asset_condition
                }}</span>
              </div>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.asset_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: DateDisposed -->
            <template #cell(DateDisposed)="data">
              {{
                dateSimple(
                  data.item.asset_is_disposed_confirmed_by_fa_coal_manager_date,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="showDetailDisposal(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showHistory(data.item)">
                <feather-icon icon="BookIcon" />
                <span class="align-middle ml-50">History</span>
              </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    userRole == 'finance'"
                  @click="editAssets(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          </b-tab>
        </b-tabs>
          </b-tab>
        </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BTabs,
  BTab,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormDatepicker,
  BFormFile,
} from "bootstrap-vue"
import axios from "axios"
import vSelect from "vue-select"
import store from "@/store"
import moment from "moment"
import { getUserData } from "@/auth/utils"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ref, onUnmounted } from "@vue/composition-api"
import { saveAs } from 'file-saver'
import AssetsList from "./List"

// Unchanged imports
const messageBox = ""
const to = ""
const cc = ""
const reason = ""
const materialRequestId = ""
const statusItems = []
const items = []
const userId = getUserData().user_id
const userRole = ""
const file = ''
const modalDetailShow = false
const modalMessage = false
const modalUpload = false
const documentsList = []
const branches = []
const assetGroups = ["Machinery (MC)", "Furniture & Fixture", "Office Equipment", "Lab Equipment", "Vehicle (VH)", "Building", "Software", "Low Value Asset", "Land"]

export default {
  components: {
  BTabs,
  BTab,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormDatepicker,
    BFormFile,
    vSelect,
  },
  data() {
    return {
      showModal: false,
      assetToDispose: null,
      assetGroups,
      branches,
      file,
      modalUpload,
      items,
      statusItems,
      materialRequestId,
      userId,
      documentsList,
      isLoadingExport: false,
      isLoadingExportFinished: false,
      isLoadingExportInQueue: false,
    }
  },
  mounted() {
    this.userRole = this.$cookies.get("UserRole")
    this.$root.$on("refreshTable", text => {
      console.log(text)
      this.$refs.refAssetsListTable.refresh()
      this.$refs.refAssetsListTableFinished.refresh()
      this.$refs.refAssetsListTableInQueue.refresh()
    })
    this.getBranches()
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = "app-assets"
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewAssetsSidebarActive = ref(false)

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ]

    const {
      fetchAssets,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAssetsListTable,
      refetchData,
      fetchAssetsNA,
      fetchAssetsOnGoing,
      fetchAssetsDisposed,
      startDateFilter,
      endDateFilter,
      filterBranch,
      filterAssetGroup,
      tableColumnsOnGoing,
      perPageOnGoing,
      currentPageOnGoing,
      totalItemsOnGoing,
      dataMetaOnGoing,
      perPageOptionsOnGoing,
      searchQueryOnGoing,
      sortByOnGoing,
      isSortDirDescOnGoing,
      refAssetsListTableOnGoing,
      refetchDataOnGoing,
      startDateFilterOnGoing,
      endDateFilterOnGoing,
      filterBranchOnGoing,
      filterAssetGroupOnGoing,
      tableColumnsNA,
      perPageNA,
      currentPageNA,
      totalItemsNA,
      dataMetaNA,
      perPageOptionsNA,
      searchQueryNA,
      sortByNA,
      isSortDirDescNA,
      refAssetsListTableNA,
      refetchDataNA,
      startDateFilterNA,
      endDateFilterNA,
      filterBranchNA,
      filterAssetGroupNA,
      tableColumnsDisposed,
      perPageDisposed,
      currentPageDisposed,
      totalItemsDisposed,
      dataMetaDisposed,
      perPageOptionsDisposed,
      searchQueryDisposed,
      sortByDisposed,
      isSortDirDescDisposed,
      refAssetsListTableDisposed,
      refetchDataDisposed,
      startDateFilterDisposed,
      endDateFilterDisposed,
      filterBranchDisposed,
      filterAssetGroupDisposed,
      resolveAssetsRoleVariant,
      resolveAssetsRoleIcon,
      resolveAssetsStatusVariant,
    } = AssetsList()

    return {
      statusOptions,
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      isAddNewAssetsSidebarActive,
      userRole,
      fetchAssets,
      fetchAssetsNA,
      fetchAssetsOnGoing,
      fetchAssetsDisposed,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAssetsListTable,
      refetchData,
      startDateFilter,
      endDateFilter,
      filterBranch,
      filterAssetGroup,
      tableColumnsOnGoing,
      perPageOnGoing,
      currentPageOnGoing,
      totalItemsOnGoing,
      dataMetaOnGoing,
      perPageOptionsOnGoing,
      searchQueryOnGoing,
      sortByOnGoing,
      isSortDirDescOnGoing,
      refAssetsListTableOnGoing,
      refetchDataOnGoing,
      startDateFilterOnGoing,
      endDateFilterOnGoing,
      filterBranchOnGoing,
      filterAssetGroupOnGoing,
      tableColumnsNA,
      perPageNA,
      currentPageNA,
      totalItemsNA,
      dataMetaNA,
      perPageOptionsNA,
      searchQueryNA,
      sortByNA,
      isSortDirDescNA,
      refAssetsListTableNA,
      refetchDataNA,
      startDateFilterNA,
      endDateFilterNA,
      filterBranchNA,
      filterAssetGroupNA,
      tableColumnsDisposed,
      perPageDisposed,
      currentPageDisposed,
      totalItemsDisposed,
      dataMetaDisposed,
      perPageOptionsDisposed,
      searchQueryDisposed,
      sortByDisposed,
      isSortDirDescDisposed,
      refAssetsListTableDisposed,
      refetchDataDisposed,
      startDateFilterDisposed,
      endDateFilterDisposed,
      filterBranchDisposed,
      filterAssetGroupDisposed,
      resolveAssetsRoleVariant,
      resolveAssetsRoleIcon,
      resolveAssetsStatusVariant,
    }
  },
  methods: {
    showConfirmationModal(asset) {
      this.assetToDispose = asset
      this.showModal = true
    },
    hideModal() {
      this.showModal = false
    },
    disposeAsset(data) {
      localStorage.setItem("assetDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-assets-dispose-finance" })
    },
    confirmDisposalByLabManager(data) {
      localStorage.setItem("assetDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-assets-dispose-lab-manager" })
    },
    confirmDisposalByRegionalManager(data) {
      localStorage.setItem("assetDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-assets-dispose-regional-manager" })
    },
    confirmDisposalByDivisionHead(data) {
      localStorage.setItem("assetDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-assets-dispose-division-head" })
    },
    confirmDisposalByFACoalManager(data) {
      localStorage.setItem("assetDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-assets-dispose-fa-coal-manager" })
    },
    getBranches() {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
        axios
            .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`, { headers })
            .then(response => {
            const itemsJoin = []
            const userData = this.$cookies.get('userData')
            const userBranch = userData.branch
            response.data.data.map(elem => {
              if (userBranch !== null && typeof userBranch !== 'undefined') {
                if (elem.branch_code === userBranch.branch_code) {
                  itemsJoin.push({
                    label: `${elem.branch_name} (${elem.branch_code})`,
                    value: elem.branch_id,
                  })
                }
              } else {
                itemsJoin.push({
                  label: `${elem.branch_name} (${elem.branch_code})`,
                  value: elem.branch_id,
                })
              }
            })
            this.branches = itemsJoin
            })
            .catch(err => {
                console.log(err)
            })
    },
    exportAsset() {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_ASSET}`
      this.isLoadingExport = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilter,
            date_end: this.endDateFilter,
            type: "ongoing",
          },
      }).then(response => {
        let filename = "report_assets"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExport = false
      })
    },
    deleteAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.document_file_name
      const ObjectName = `${document.document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
        id: document.document_id,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            this.showDocuments(document.document_asset_id)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Delete Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    downloadAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.document_file_name
      const ObjectName = `${document.document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            window.open(response.data.Payload, "_blank")
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Download Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    deleteAssets(id) {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_MATERIAL_REQUEST}${id}`,
          { headers },
        )
        .then(response => {
          console.log(response)
          if (response.data.success === true) {
            this.$refs.refAssetsListTable.refresh()
            this.$refs.refAssetsListTableFinished.refresh()
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Congratulation",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Your data has been deleted!",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Notification",
                icon: "BellIcon",
                variant: "danger",
                text: "Something went wrong",
              },
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    editAssets(data) {
      localStorage.setItem("assetDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-assets-edit" })
    },
    confirmAssets(data) {
      localStorage.setItem("assetDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-assets-confirm" })
    },
    sendAssets(data) {
      localStorage.setItem("assetDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-assets-send" })
    },
    addNew() {
      this.$router.push({ name: "apps-assets-create" })
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    showDetail(item) {
      localStorage.setItem("assetDetail", JSON.stringify(item))
      this.$router.push({ name: "apps-assets-detail" })
    },
    showDetailDisposal(item) {
      localStorage.setItem("assetDetail", JSON.stringify(item))
      this.$router.push({ name: "apps-assets-dispose-detail" })
    },
    showHistory(item) {
      localStorage.setItem("assetDetail", JSON.stringify(item))
      this.$router.push({ name: "apps-assets-history" })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get("userPermissions").split(",")
      return permissions.includes(permission) ? true : false
    },
    cutString(string) {
      const lengthOf = string.length
      return lengthOf > 30 ? `${string.substring(0, 30)} ...` : string
    },
    showDocuments(id) {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.materialRequestId = id
      this.modalUpload = true
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}mr/${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            console.log("Fetching Data")
            if (response.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
      },
    sendDocument() {
      const userToken = this.$cookies.get('userToken')
      const header = {
        'Content-Type': 'multipart/form-dataitem',
        Authorization: `Bearer ${userToken}`,
      }
      const formData = new FormData()
      if (typeof this.file[0] !== 'undefined') {
      formData.append('file', this.file[0], this.file[0].name)
      formData.append('document_asset_id', this.materialRequestId)
      formData.append('time', moment())
    axios
      .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_DOCUMENT}`, formData, { headers: header })
      .then(async response => {
        if (response.data.success === true) {
            this.$refs.refAssetsListTable.refresh()
            this.$refs.refAssetsListTableFinished.refresh()
            this.$refs.refAssetsListTableInQueue.refresh()
            this.$refs.uploadFile.reset()
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}mr/${this.materialRequestId}`,
          { headers: header },
        )
        .then(response2 => {
          if (response2.data.success === true) {
            if (response2.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response2.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Purchase Request Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please Upload The Document!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"
</style>
